<template>
    <div class="pd20 bg-w">
        <div class="xhm-table-gray mt20">
            <a-table rowKey="template_id" :columns="columns" :pagination="pagination" @change="change"
                :data-source="list" :loading="loading">
                <template slot="create_time" slot-scope="record,index">
                    {{ timestampToTime(record.create_time) }}
                </template>
                <template slot="action" slot-scope="record,index">
                    <a-button type="link" @click="releaseModal(record)">发布</a-button>
                    <a-button type="link" @click="del(record)" style="color:red">删除</a-button>
                </template>
            </a-table>
        </div>
        <a-modal width="400px" title="发布版本" :visible="releaseShow" @ok="release" @cancel="releaseShow=false">
            <a-form-model :model="releaseData" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="类型">
                    <a-select v-model="releaseData.miniapp_type" style="width: 200px;">
                        <a-select-option value="mp">mp</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="模板ID">
                    <a-input v-model="releaseData.template_id" style="width: 230px;" type="number" />
                </a-form-model-item>
                <a-form-model-item label="版本号">
                    <a-input v-model="releaseData.version" style="width: 230px;" />
                </a-form-model-item>
                <a-form-model-item label="描述">
                    <a-textarea v-model="releaseData.version_desc" :rows="4" style="width: 230px;" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { apiVersionTemplate, apiVersionTemplateRelease, apiVersionTemplateDelete } from '../../utils/js/api';
export default {
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '模板id', dataIndex: 'template_id', align: 'center' },
                { title: '版本号', dataIndex: 'user_version', align: 'center' },
                { title: '描述', dataIndex: 'user_desc', align: 'center' },
                { title: '草稿ID', dataIndex: 'draft_id', align: 'center' },
                { title: '提交时间', key: 'create_time', align: 'center', scopedSlots: { customRender: 'create_time' } },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            list: [],
            releaseShow:false,
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            releaseData: {
                version: '',
                template_id: 0,
                version_desc: '',
                miniapp_type:'mp'
            }
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            apiVersionTemplate().then(res => {
                console.log('res', res)
                this.pagination.total = res.template_list.length;
                res.template_list.sort((a, b) => b.template_id - a.template_id);
                this.list = res.template_list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        releaseModal(detail){
            this.releaseData.template_id = detail.template_id;
            this.releaseData.version = detail.user_version;
            this.releaseData.version_desc = detail.user_desc;

            this.releaseShow = true;
        },
        release() {//发布
            apiVersionTemplateRelease(this.releaseData).then(res => {
                this.$message.success('发布成功');
                this.releaseShow = false;
            })
        },
        del(detail) {
            this.$confirm({
                title: '删除确认',
                content: '确定要删除模板' + detail.user_version,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    apiVersionTemplateDelete({ template_id: detail.template_id }).then(res => {
                        this.$message.success('删除成功');
                        this.getList();
                    })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });

        },
        change(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        }
    }
}
</script>

<style></style>
